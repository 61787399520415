var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('w-data-table',{attrs:{"loading":_vm.loading,"title":_vm.$trans('Groups'),"icon":"DEPARTMENT","headers":_vm.groupsHeaders,"items":_vm.items,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([(!_vm.readOnly)?{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":"","tile":"","color":"primary"},on:{"click":_vm.onShowAddForm}},[_c('w-icon',{attrs:{"value":"ADD"}})],1)]},proxy:true}:null,{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex py-2"},[_c('div',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.title)+" ("+_vm._s(item.level.title)+")")]),_c('small',{staticClass:"caption d-none d-md-block"},[_vm._v(" "+_vm._s(item.level.school.title)+" ")])])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.onDetachGroup(item)}}},[_c('w-icon',{attrs:{"value":"CLOSE"}})],1)]}},(!_vm.readOnly)?{key:"footer",fn:function(){return [_c('v-btn',{staticClass:"py-6",attrs:{"text":"","block":"","tile":"","color":"primary"},on:{"click":_vm.onShowAddForm}},[_c('w-icon',{attrs:{"left":"","value":"ADD"}}),_vm._v(" "+_vm._s(_vm.addGroupTitle)+" ")],1)]},proxy:true}:null],null,true)}),_c('w-dialog-drawer',{attrs:{"title":_vm.addGroupTitle,"width":"500"},model:{value:(_vm.isDialog),callback:function ($$v) {_vm.isDialog=$$v},expression:"isDialog"}},[_c('div',{staticClass:"pa-4"},[_c('v-select',{staticClass:"mb-4",attrs:{"label":_vm.$trans('Level'),"prepend-icon":_vm.icons.levelIcon,"clearable":"","hide-details":"","outlined":"","dense":"","items":_vm.levelsItems,"item-text":"title","item-value":"uuid"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticStyle:{"height":"3rem"},attrs:{"dense":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))]),_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(_vm._s(item.school.title))])],1)],1)]}}]),model:{value:(_vm.selectedLevel),callback:function ($$v) {_vm.selectedLevel=$$v},expression:"selectedLevel"}}),_c('v-autocomplete',{attrs:{"disabled":!_vm.selectedLevel,"items":_vm.foundGroups,"loading":_vm.isSearchingGroup,"search-input":_vm.searchGroup,"outlined":"","hide-no-data":"","hide-selected":"","clearable":"","hide-details":"","item-text":"title","item-value":"uuid","label":_vm.$trans('Group'),"placeholder":_vm.$trans('Start typing to Search'),"prepend-icon":_vm.icons.groupIcon},on:{"update:searchInput":function($event){_vm.searchGroup=$event},"update:search-input":function($event){_vm.searchGroup=$event},"click:clear":function($event){_vm.foundGroups = []}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"my-2",attrs:{"dense":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title)+" ("+_vm._s(item.level.title)+")")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("schoolTitle")(item.level.school_uuid)))])],1)],1)]}}]),model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}}),_c('div',{staticClass:"d-flex mt-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.onAttachGroup}},[_vm._v(" "+_vm._s(_vm.$trans('Add'))+" ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }