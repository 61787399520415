<template>
    <div class="course-capacity-amounts">
        <w-data-table
            :loading="isDataLoading"
            :title="$trans('Capacity')"
            icon="CAPACITY"
            :headers="capacitiesHeaders"
            :items="items"
            :items-per-page="-1"
            hide-default-footer
        >
            <template #filters>

            </template>

            <template #actions>
                <div class="d-flex align-center mr-4">
                    <w-icon left value="mdi-clock-check"/>
                    <div class="ms-1">
                        <h4 class="font-weight-semibold text-truncate">
                            {{ stats.defined }} / {{ stats.total }} {{ $trans('hours') }}
                        </h4>
                        <v-progress-linear
                            :value="stats.percent"
                            color="success"
                        ></v-progress-linear>
                    </div>
                </div>
                <v-btn
                    v-if="!readOnly"
                    icon
                    color="primary"
                    @click="onShowForm(null)"
                >
                    <w-icon value="ADD"/>
                </v-btn>
            </template>

            <template #item.title="{ item }">
                <div class="font-weight-bold">{{ item.lesson_type.title }}</div>
                <small class="caption">
                    {{ item.lesson_type.description }}
                </small>
            </template>

            <template #item.actions="{ item }">
                <div class="d-flex align-center justify-end text-truncate">
                    <v-btn
                        color="success"
                        icon
                        @click="onShowForm(item)"
                    >
                        <w-icon value="EDIT"/>
                    </v-btn>
                    <v-btn
                        color="error"
                        icon
                        @click="onDetachCapacity(item)"
                    >
                        <w-icon value="CLOSE"/>
                    </v-btn>
                </div>
            </template>

            <template v-if="!readOnly" #footer>
                <v-btn
                    text
                    block
                    tile
                    color="primary"
                    class="py-6"
                    @click="onShowForm(null)"
                >
                    <w-icon left value="ADD"/>
                    {{ addCapacityTitle }}
                </v-btn>
            </template>
        </w-data-table>
        <w-dialog-drawer
            :key="componentKey"
            v-model="isDialog"
            :title="formTitle"
            :disabled="isDialogDisabled"
            width="500"
        >
            <div v-if="isFormItem" class="px-4 pt-3">
                <v-list dense>
                    <v-card outlined>
                        <v-list-item>
                            <v-list-item-icon>
                                <w-icon value="LESSON" />
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ formItem.lesson_type.title }}</v-list-item-title>
                                <v-list-item-subtitle>{{ formItem.lesson_type.description }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-list>
            </div>
            <div class="pa-4">
                <w-simple-form
                    :fields="formFields"
                    :item="formItem"
                    :errors="responseErrors"
                    @save="onAttachCapacity"
                    @update="onUpdateCapacity"
                >
                    <template #field.lesson_type_uuid="{ data, errors, attrs }">
                        <v-select
                            v-model="data.lesson_type_uuid"
                            v-bind="attrs"
                            :error-messages="errors"
                        >
                            <template v-slot:item="{ item, attrs, on }">
                                <v-list-item dense v-bind="attrs" v-on="on" style="height: 3rem">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                        <v-list-item-subtitle class="caption">
                                            {{ item.description }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </template>
                </w-simple-form>
            </div>
        </w-dialog-drawer>
    </div>
</template>

<script>
import { CAPACITY, CLOCK, LESSON } from '@/widgets/components/WIcon/icons'
import { required } from '@/utils/validation'
import ConfirmationSets from '@/utils/ConfirmationSets'
import {
    deleteCapacityAmount,
    getCapacityAmountsCollection,
    storeCapacityAmount,
    updateCapacityAmount
} from '@apps/school/api/capacityAmounts'
import hasLessonTypesPropertyMixin from '@apps/school/mixins/hasLessonTypesPropertyMixin'

export default {
    name: 'CourseCapacityAmounts',
    mixins: [
        hasLessonTypesPropertyMixin
    ],
    props: {
        uuid: {
            type: String,
            default: undefined
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    filters: {
        courseGroupsFilter(groups) {
            const items = groups.map(o => o.title)
            return items.join(', ')
        }
    },
    data() {
        return {
            componentKey: 0,
            loading: false,
            stats: {
                total: 0,
                defined: 0,
                percent: 0
            },
            items: [],
            isLoading: false,
            isDialog: false,
            isDialogDisabled: false,
            icons: {
                capacity: CAPACITY,
                lesson: LESSON,
                clock: CLOCK
            },
            formItem: {},
            rules: {
                required
            },
            responseErrors: {}
        }
    },
    computed: {
        isDataLoading() {
            return this.loading || this.isLoading
        },
        isFormItem() {
            return Object.keys(this.formItem).length > 0
        },
        addCapacityTitle() {
            return `${this.$trans('Add capacity')}`
        },
        formTitle() {
            return this.isFormItem
                ? this.$trans('Update capacity')
                : this.$trans('Add capacity')
        },
        capacitiesHeaders() {
            const headers = [
                {
                    text: this.$trans('Lesson type'),
                    value: 'title',
                    sortable: false
                },
                {
                    text: this.$trans('Hours'),
                    align: 'center',
                    value: 'value',
                    sortable: false
                }
            ]

            if(!this.readOnly) {
                headers.push({
                    value: 'actions',
                    align: 'right',
                    sortable: false
                })
            }

            return headers
        },
        formFields() {
            const fields = []

            if (!this.isFormItem) {
                fields.push({
                    name: 'lesson_type_uuid',
                    type: 'v-select',
                    props: {
                        label: this.$trans('Lesson type'),
                        items: this.lessonTypes,
                        itemValue: 'uuid',
                        itemText: 'title',
                        dense: true,
                        outlined: true,
                        prependIcon: this.icons.lesson,
                        rules: [ required ]
                    }
                })
            }

            fields.push({
                name: 'value',
                type: 'v-text-field',
                props: {
                    label: this.$trans('Capacity'),
                    persistentHint: true,
                    type: 'number',
                    dense: true,
                    outlined: true,
                    prependIcon: this.icons.capacity,
                    prependInnerIcon: this.icons.clock,
                    suffix: this.$trans('hours'),
                    rules: [ required ]
                }
            })

            return fields
        }
    },
    watch: {
        isDialog(value) {
            if (!value) {
                this.componentKey++
                this.resetFormFlags()
            }
        }
    },
    async mounted() {
        await this.fetchCapacityAmounts()
    },
    methods: {
        async fetchCapacityAmounts() {
            if (!this.uuid) {
                return
            }

            try {
                this.loading = true

                const response = await getCapacityAmountsCollection({
                    course: this.uuid,
                    with: 'lessonType:title,description,uuid'
                })

                this.items = response.data.data
                this.stats = response.data.stats

                this.loading = false
            } catch (e) {
                this.loading = false
            }
        },
        resetFormFlags() {
            this.formItem = {}
        },
        async onShowForm(item) {
            this.resetFormFlags()
            this.formItem = Object.assign({}, item)
            this.isDialog = true
        },
        onClearFoundCourses() {
            this.foundCourses = []
            this.selectedCourse = null
        },
        onSelectCourse(uuid) {
            this.selectedCourse = uuid
        },
        onAttachCapacity(data) {
            data.course_uuid = this.uuid

            this.isDialogDisabled = true

            storeCapacityAmount(data)
                .then(() => {
                    this.isDialog = false
                    this.isDialogDisabled = false

                    this.fetchCapacityAmounts()

                    this.$emit('synced')
                })
                .catch(error => {
                    this.isDialogDisabled = false

                    if (error.response.data.errors) {
                        this.responseErrors = error.response.data.errors
                    }
                })
        },
        onUpdateCapacity(data) {
            this.isDialogDisabled = true

            updateCapacityAmount(this.formItem.uuid, data)
                .then(() => {
                    this.isDialog = false
                    this.isDialogDisabled = false

                    this.fetchCapacityAmounts()

                    this.$emit('synced')
                })
                .catch(error => {
                    this.isDialogDisabled = false

                    if (error.response.data.errors) {
                        this.responseErrors = error.response.data.errors
                    }
                })
        },
        async onDetachCapacity(item) {
            const isConfirm = await this.$confirm(ConfirmationSets.deleteSet({
                title: `${this.$trans('Delete')} ${this.$trans('Capacity')}`
            }))

            if (!isConfirm) {
                return
            }

            this.isLoading = true

            deleteCapacityAmount(item.uuid)
                .then(() => {
                    this.isLoading = false

                    this.fetchCapacityAmounts()

                    this.$emit('synced')
                })
                .catch(() => {
                    this.isLoading = false
                })
        }
    }
}
</script>

<style lang=scss>

</style>
