<template>
    <div>
        <w-data-table
            :loading="loading"
            :title="$trans('Groups')"
            icon="DEPARTMENT"
            :headers="groupsHeaders"
            :items="items"
            hide-default-header
            hide-default-footer
        >
            <template v-if="!readOnly" #actions>
                <v-btn
                    icon
                    tile
                    color="primary"
                    @click="onShowAddForm"
                >
                    <w-icon value="ADD"/>
                </v-btn>
            </template>
            <template #item.title="{ item }">
                <div class="d-flex py-2">
                    <div>
                        <div class="font-weight-bold">{{ item.title }} ({{ item.level.title }})</div>
                        <small class="caption d-none d-md-block">
                            {{ item.level.school.title }}
                        </small>
                    </div>
                </div>
            </template>

            <template #item.actions="{ item }">
                <v-btn
                    color="error"
                    icon
                    @click="onDetachGroup(item)"
                >
                    <w-icon value="CLOSE"/>
                </v-btn>
            </template>

            <template v-if="!readOnly" #footer>
                <v-btn
                    text
                    block
                    tile
                    color="primary"
                    class="py-6"
                    @click="onShowAddForm"
                >
                    <w-icon left value="ADD"/>
                    {{ addGroupTitle }}
                </v-btn>
            </template>
        </w-data-table>
        <w-dialog-drawer
            v-model="isDialog"
            :title="addGroupTitle"
            width="500"
        >
            <div class="pa-4">
                <v-select
                    v-model="selectedLevel"
                    :label="$trans('Level')"
                    :prepend-icon="icons.levelIcon"
                    clearable
                    hide-details
                    outlined
                    dense
                    :items="levelsItems"
                    item-text="title"
                    item-value="uuid"
                    class="mb-4"
                >
                    <template v-slot:item="{ item, attrs, on }">
                        <v-list-item dense v-bind="attrs" v-on="on" style="height: 3rem">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                <v-list-item-subtitle class="caption">{{ item.school.title }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-select>
                <v-autocomplete
                    :disabled="!selectedLevel"
                    v-model="selectedGroup"
                    :items="foundGroups"
                    :loading="isSearchingGroup"
                    :search-input.sync="searchGroup"
                    outlined
                    hide-no-data
                    hide-selected
                    clearable
                    hide-details
                    item-text="title"
                    item-value="uuid"
                    :label="$trans('Group')"
                    :placeholder="$trans('Start typing to Search')"
                    :prepend-icon="icons.groupIcon"
                    @click:clear="foundGroups = []"
                >
                    <template v-slot:item="{ item, attrs, on }">
                        <v-list-item dense v-bind="attrs" v-on="on" class="my-2">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }} ({{ item.level.title }})</v-list-item-title>
                                <v-list-item-subtitle>{{ item.level.school_uuid | schoolTitle }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-autocomplete>
                <div class="d-flex mt-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        color="primary"
                        @click="onAttachGroup"
                    >
                        {{ $trans('Add') }}
                    </v-btn>
                </div>
            </div>
        </w-dialog-drawer>
    </div>
</template>

<script>
import { GROUP, LEVEL } from '@/widgets/components/WIcon/icons'
import ConfirmationSets from '@/utils/ConfirmationSets'
import { attachGroupToCourse, detachGroupFromCourse } from '@apps/school/api/courses'
import { getGroupsCollection } from '@apps/school/api/groups'
import { getLevelsCollection } from '@apps/school/api/levels'
import Debugger from '@/utils/Debugger'

export default {
    name: 'CourseGroups',
    props: {
        uuid: {
            type: String,
            default: null
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            items: [],
            isDialog: false,
            isSearchingGroup: false,
            levelsItems: [],
            selectedLevel: null,
            selectedGroup: null,
            searchGroup: '',
            foundGroups: [],
            icons: {
                groupIcon: GROUP,
                levelIcon: LEVEL
            }
        }
    },
    computed: {
        addGroupTitle() {
            return `${this.$trans('Add group')}`
        },
        groupsHeaders() {
            const headers = [
                {
                    text: this.$trans('group'),
                    value: 'title'
                }
            ]

            if(!this.readOnly) {
                headers.push({
                    value: 'actions',
                    align: 'right'
                })
            }

            return headers
        }
    },
    watch: {
        searchGroup(val) {
            if (!val) {
                this.isSearchingGroup = false
                return
            }

            // Items have already been loaded
            if (this.foundGroups.length > 0) return

            // Items have already been requested
            if (this.isSearchingGroup) return

            this.isSearchingGroup = true

            // Lazily load input items

            getGroupsCollection({
                search: val,
                level: this.selectedLevel,
                with: 'level.school',
                orderBy: 'level_uuid'
            })
                .then(response => {
                    this.foundGroups = response.data.data
                })
                .catch(err => {
                    Debugger.log(err)
                })
                .finally(() => (this.isSearchingGroup = false))
        }
    },
    async mounted() {
        await this.fetchLevels()
        await this.fetchGroups()
    },
    methods: {
        async fetchGroups() {
            if (!this.uuid) {
                return
            }

            this.loading = true

            const payload = {
                level: 'all',
                course: this.uuid,
                with: 'level.school',
                orderBy: 'level_uuid'
            }

            try {
                const response = await getGroupsCollection(payload)
                this.items = response.data.data || []

                this.loading = false

                this.$emit('loaded', this.items)
            } catch (errors) {
                this.loading = false

                Debugger.log(errors)
            }
        },
        async fetchLevels() {
            const payload = {
                school: 'all',
                with: 'school',
                orderBy: 'school_uuid'
            }

            try {
                const response = await getLevelsCollection(payload)

                this.levelsItems = response.data.data
            } catch (error) {
                Debugger.log(error)
            }
        },
        onShowAddForm() {
            this.isDialog = true
            this.foundGroups = []
            this.selectedGroup = null
        },
        onAttachGroup() {
            if (!this.selectedGroup) {
                return
            }

            attachGroupToCourse(this.uuid, this.selectedGroup)
                .then(() => {
                    this.isDialog = false
                    this.selectedGroup = null
                    this.selectedLevel = null
                    this.foundGroups = []

                    this.fetchGroups()

                    this.$emit('synced')
                })
        },
        async onDetachGroup(item) {
            const isConfirm = await this.$confirm(ConfirmationSets.deleteSet({
                title: `${this.$trans('Delete')} ${item.title}`
            }))

            if (!isConfirm) {
                return
            }

            detachGroupFromCourse(this.uuid, item.uuid)
                .then(() => {
                    this.isDialog = false
                    this.selectedGroup = null
                    this.selectedLevel = null
                    this.foundGroups = []

                    this.fetchGroups()

                    this.$emit('synced')
                })
        }
    }
}
</script>

<style lang=scss>

</style>
