var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"course-capacity-amounts"},[_c('w-data-table',{attrs:{"loading":_vm.isDataLoading,"title":_vm.$trans('Capacity'),"icon":"CAPACITY","headers":_vm.capacitiesHeaders,"items":_vm.items,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"filters",fn:function(){return undefined},proxy:true},{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex align-center mr-4"},[_c('w-icon',{attrs:{"left":"","value":"mdi-clock-check"}}),_c('div',{staticClass:"ms-1"},[_c('h4',{staticClass:"font-weight-semibold text-truncate"},[_vm._v(" "+_vm._s(_vm.stats.defined)+" / "+_vm._s(_vm.stats.total)+" "+_vm._s(_vm.$trans('hours'))+" ")]),_c('v-progress-linear',{attrs:{"value":_vm.stats.percent,"color":"success"}})],1)],1),(!_vm.readOnly)?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.onShowForm(null)}}},[_c('w-icon',{attrs:{"value":"ADD"}})],1):_vm._e()]},proxy:true},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.lesson_type.title))]),_c('small',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.lesson_type.description)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-end text-truncate"},[_c('v-btn',{attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.onShowForm(item)}}},[_c('w-icon',{attrs:{"value":"EDIT"}})],1),_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.onDetachCapacity(item)}}},[_c('w-icon',{attrs:{"value":"CLOSE"}})],1)],1)]}},(!_vm.readOnly)?{key:"footer",fn:function(){return [_c('v-btn',{staticClass:"py-6",attrs:{"text":"","block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.onShowForm(null)}}},[_c('w-icon',{attrs:{"left":"","value":"ADD"}}),_vm._v(" "+_vm._s(_vm.addCapacityTitle)+" ")],1)]},proxy:true}:null],null,true)}),_c('w-dialog-drawer',{key:_vm.componentKey,attrs:{"title":_vm.formTitle,"disabled":_vm.isDialogDisabled,"width":"500"},model:{value:(_vm.isDialog),callback:function ($$v) {_vm.isDialog=$$v},expression:"isDialog"}},[(_vm.isFormItem)?_c('div',{staticClass:"px-4 pt-3"},[_c('v-list',{attrs:{"dense":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('w-icon',{attrs:{"value":"LESSON"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.formItem.lesson_type.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.formItem.lesson_type.description))])],1)],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"pa-4"},[_c('w-simple-form',{attrs:{"fields":_vm.formFields,"item":_vm.formItem,"errors":_vm.responseErrors},on:{"save":_vm.onAttachCapacity,"update":_vm.onUpdateCapacity},scopedSlots:_vm._u([{key:"field.lesson_type_uuid",fn:function(ref){
var data = ref.data;
var errors = ref.errors;
var attrs = ref.attrs;
return [_c('v-select',_vm._b({attrs:{"error-messages":errors},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticStyle:{"height":"3rem"},attrs:{"dense":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))]),_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.description)+" ")])],1)],1)]}}],null,true),model:{value:(data.lesson_type_uuid),callback:function ($$v) {_vm.$set(data, "lesson_type_uuid", $$v)},expression:"data.lesson_type_uuid"}},'v-select',attrs,false))]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }